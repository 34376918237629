<script setup lang="ts">
import { notification } from 'ant-design-vue'
import { StarFilled, StarOutlined } from '@ant-design/icons-vue'
import { moneyFormat } from '~/shared/number'
import type { ProductType } from '~/models/product'
import { callFunc } from '~/realm/data/call'
import { useAclStore } from '~/store/acl'
import { mediumFirstSrcset } from '~/shared/im'
import FavoriteHeartComponent from '~/components/favorite/heart.vue'

const props = defineProps({
  product: {
    type: Object as () => ProductType,
    required: true,
  },
  loading: {
    type: Boolean,
    required: false,
  },
})
const router = useRouter()
const $acl = useAclStore()
const zero = ref<Number>(5)
const rating = ref<Number>(0)

// add non variant only
const addToCart = async () => {
  try {
    const res = await callFunc('addCart', {
      sessionToken: $acl.cartSessionToken,
      productId: props.product._id,
      variants: {},
      quantity: 1,
    })
    if (res) {
      notification.success({
        message: 'Added to Cart',
        placement: 'topLeft',
      })
      console.log('res...', res)
      await $acl.loadCart(res.sessionToken)
    }
    return $acl.setCartSession(res.sessionToken)
  }
  catch (err) {
    console.log(err)
    notification.error({
      message: 'An error has occurred',
      placement: 'topLeft',
    })
  }
}

const discountPercent = computed(() => {
  if (!props.product.normalUnitPrice || !props.product.unitPrice) {
    return 0.0
  }

  if (props.product.normalUnitPrice === props.product.unitPrice) {
    return 0.0
  }

  return Math.floor(props.product.normalUnitPrice - props.product.unitPrice / 100)
})
</script>

<template>
  <a-card hoverable size="small" class="product-card m-0 p-2 w-full md:w-[220px]">
    <template #cover>
      <div @click="router.push(`/products/${product._id!.toString()}`)">
        <img
          v-if="product?.medium && product?.medium.length > 0"
          loading="lazy"
          :srcset="mediumFirstSrcset(product.medium)"
          :alt="props.product.name"
          class="w-full object-cover"
          style="height: 200px;"
        />
        <img
          v-else
          loading="lazy"
          src="@/assets/CelElements.svg"
          style="width: 100%; height: 200px;"
        />
      </div>
    </template>
    <div class="absolute flex top-3.3 right-2.9">
      <a-badge v-if="discountPercent > 0.0" count="HOT" />
      <a-badge
        v-if="discountPercent > 0.0"
        class="ml-0.5"
        count="NEW"
        :number-style="{
          backgroundColor: '#3498db',
          color: '#fff',
        }"
      />
      <a-badge
        v-if="discountPercent > 0.0"
        class="ml-0.5"
        :count="`${discountPercent}%`"
        :number-style="{ backgroundColor: 'green' }"
      />
    </div>
    <div class="mt-1">
      <div @click="router.push(`/products/${product._id!.toString()}`)">
        <!-- <a-rate v-if="!props.product?.averageRate || props.product?.averageRate === 0" v-model:value="zero" class="rating" disabled allow-half>
          <template #character>
            <StarOutlined style="color: grey;" />
          </template>
        </a-rate> -->
        <a-rate v-if="product && product.averageRate && product.averageRate > 0" class="rating" :value="props.product?.averageRate" disabled allow-half>
          <template #character>
            <StarFilled />
          </template>
        </a-rate>
      </div>
      <!-- <FavoriteHeartComponent class="" :product="props.product" :loading="props.loading" :size="18"/> -->
    </div>
    <a-row @click="router.push(`/products/${product._id!.toString()}`)">
      <a-col :span="24">
        <a-tooltip>
          <template #title>{{ props.product.name }}</template>
          <span class="product-title mt-1" style="font-size: 12px; color: black;">
            {{ props.product.name }}
          </span>
        </a-tooltip>
      </a-col>
    </a-row>
    <a-divider class="mt-1 mb-2" style="height: 1px; background-color: darkgrey" />
    <a-row @click="router.push(`/products/${product._id!.toString()}`)">
      <a-col :span="24" class="flex justify-between items-baseline lg:text-[0.90em]" style="color: black;">
        <p style=""><strong class="mr-1">{{ `RM${moneyFormat(props.product.unitPrice)}` }}</strong>
          <small v-if="discountPercent > 0.0" style="text-decoration-line: line-through">{{ `RM${moneyFormat(props.product.normalUnitPrice)}` }}</small>
        </p>
        <!-- <p style=""><small>120 solds</small></p> -->
      </a-col>
    </a-row>
    <div :span="24" class="mt-2">
      <a-button v-if="props.product.hasVariant" class="buttonAddToCart" @click="router.push(`/products/${product._id!.toString()}`)">
        VIEW MORE
      </a-button>
      <a-button v-else class="buttonAddToCart" @click="addToCart">
        ADD TO CART
      </a-button>
    </div>
  </a-card>
</template>

<style scoped>
.product-title {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rating {
  font-size: 17px;
}

.buttonAddToCart {
  display: block;
  width: 100%;
  border: none;
  background-color: rgb(21, 112, 21);
  font-size: 14px;
  color: white;
  cursor: pointer;
  text-align: center;
}
/* .hide {
  display: none;
}
.card:hover .hide {
  display: block;
} */

/* .badge {
    position: absolute;
    padding: 5px;
    top: 8px;
    right: 16px;
    background-color: #30421c;
    border-radius: 5px;
    color: whitesmoke;
} */
</style>
